var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "700", height: "450", zIndex: "99999" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "vx-row location-list-autocomplete" }, [
          !_vm.noSave
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", {}, [
                    _vm._v(
                      "Location Name / Alias (this is what is displayed to the customer)"
                    ),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:100",
                        expression: "'required|max:100'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { name: "Location Name" },
                    model: {
                      value: _vm.locationState.locationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.locationState, "locationName", $$v)
                      },
                      expression: "locationState.locationName",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Location Name"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-4" },
            [
              _c("label", {}, [
                _vm._v(
                  "Search Location (search for an item from the autocomplete)"
                ),
              ]),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.items,
                  "get-label": _vm.getLabel,
                  "component-item": _vm.itemTemplate,
                },
                on: {
                  "update-items": _vm.updateItems,
                  input: _vm.updateSettings,
                },
                model: {
                  value: _vm.itemInput,
                  callback: function ($$v) {
                    _vm.itemInput = $$v
                  },
                  expression: "itemInput",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "vs-button",
            {
              staticClass: "ml-2",
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.saveLocation },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }